import Dropdown from '../../components/1-atoms/dropdown/dropdown';

export default class DropdownEvent extends CustomEvent<null> {
  dropdown: Dropdown;
  currentOption: HTMLElement | undefined;

  constructor(dropdown: Dropdown, currentOption?: HTMLElement) {
    super('dropdown:change', { bubbles: true, detail: null });

    this.dropdown = dropdown;
    this.currentOption = currentOption;
  }
}
